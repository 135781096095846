import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Carousel, Image } from 'react-bootstrap'
import Message from '../components/Message'
import { listTopProducts } from '../actions/productActions'

function ProductCarouse() {
    const dispatch = useDispatch()
    
    const topProductRating = useSelector(state => state.topProductRating)
    const { error, loading, products } = topProductRating


    useEffect(() => {
        dispatch(listTopProducts())
    },[dispatch])



  return ( 
          loading ? '' 
                : error
                ? '' //<Message variant='danger'>{error}</Message>
                : ( 
                  <Carousel pause='hover' className='bg-dark cur-mn'>
                      {products.map((product) => (
                        <Carousel.Item key={product._id}>
                            <Link to={`/product/${product._id}`}>

                              <Image src={product.image} alt={product.name} />
                              <Carousel.Caption className='carousel.caption'>
                                <h4>{product.name} (${product.price})</h4>
                              </Carousel.Caption>

                          </Link>
                        </Carousel.Item>  
                      ))}
                  </Carousel>
                )
  )
}

export default ProductCarouse