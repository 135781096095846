import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import SearchBox from '../components/SearchBox'
import ProductCarousel from '../components/ProductCarousel'
import Paginate from '../components/Paginate'
import { listProducts } from '../actions/productActions'

//import Products from '../product'


function HomeScreen({ history }) {
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const {error, loading, products, page, pages} = productList


    let keyword = history.location.search
    useEffect(() => {
        dispatch(listProducts(keyword))
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, keyword])

    return (
        <div>
            <small className='home-search-mn'><SearchBox /></small>
            {!keyword && <ProductCarousel />}
            <h1 className='head-size'>Latest Products</h1>
            {loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                    :
                    <div>
                        <Row>
                            {products.map(product => (
                                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                                    <Product product = {product}/>
                                </Col>
                            ))}
                        </Row>
                        <Paginate page={page} pages={pages} keyword={keyword}/>
                    </div>
            }

        </div>
    )
}

export default HomeScreen
