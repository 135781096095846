import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader() {
    return (
        <Spinner
            animation= 'border'
            role='status'
            style={{
                height: '100px',
                width: '100px',
                margin: 'auto',
                display: 'block',
                color: '#42f5d1'
            }}
        >

        </Spinner>
            
    )
}

export default Loader
