import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown, Image} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import logo from '../logo.png'


function Header() {

        const userLogin = useSelector(state => state.userLogin)
        const { userInfo } = userLogin

        const dispatch = useDispatch()

        const cart = useSelector(state => state.cart)
        const { cartItems } = cart

       // const itemCount = cartItems.reduce((acc, item) => acc + item.qty, 0 )



        const logoutHandler = () => {
            dispatch(logout())
        }

    return (
        <header>
                <Navbar bg="dark" expand="lg" variant='dark' className='nav-mng'>
                <Container>
                    <LinkContainer to='/'>
                            <Navbar.Brand>
                               <Image src={logo} alt="brand_name_u" width={150} height={35}/>
                            </Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <strong className='search-mn'><SearchBox /></strong>

                    <Nav className="mx-2">
                        <LinkContainer to='/cart'>
                                <Nav.Link>{cartItems}</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to='/'>
                                <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        {userInfo ? (
                                <NavDropdown title={userInfo.name} id='username'>
                                    <LinkContainer to='/profile'>
                                        <NavDropdown.Item>Profile</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                                </NavDropdown>

                                ):(
                                    <LinkContainer to='/'>
                                    <Nav.Link >Latest Product</Nav.Link>
                                </LinkContainer>

                                )}

                            {userInfo && userInfo.isAdmin && (
                                <NavDropdown title="Admin" id="basic-nav-dropdown">
                                <LinkContainer to='/admin/userlist'>
                                    <NavDropdown.Item>User</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/productlist'>
                                    <NavDropdown.Item>Product</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to='/admin/orderlist'>
                                    <NavDropdown.Item>Orders</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>

                            )}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>

    )
}

export default Header
