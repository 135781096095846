import React, {useState} from 'react'
import { Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

function SearchBox() {

    let history = useHistory()

    const [keyword, setKeyword] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        if (keyword) {
            history.push(`/?keyword=${keyword}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }



  return (
        <Form onSubmit={submitHandler} className='d-flex'>
            <Form.Control
            type='text'
            name='q'
            placeholder='Search'
            onChange={(e) => setKeyword(e.target.value)}
            className='mr-sm-1 mx-1 rounded border-danger'
            ></Form.Control>

            <Button
            type='submit'
            variant='outline-warning'
            className='p-2 rounded'
            >
                Search
            </Button>
        </Form>
    
  )
}

export default SearchBox