import { Container } from 'react-bootstrap'
import { HashRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'

import HomeScreen from './Screen/HomeScreen'
import ProductScreen from './Screen/ProductScreen'
import CartScreen from './Screen/CartScreen'
import LoginScreen from './Screen/LoginScreen'
import RegisterScreen from './Screen/RegisterScreen'
import ProfileScreen from './Screen/ProfileScreen'
import ShippingScreen from './Screen/ShippingScreen'
import PaymentScreen from './Screen/PaymentScreen'
import PlaceOrderScreen from './Screen/PlaceOrderScreen'
import  OrderScreen  from './Screen/OrderScreen'
import  UserListScreen   from './Screen/UserListScreen'
import  UserEditScreen   from './Screen/UserEditScreen'
import ProductListScreen from './Screen/ProductListScreen'
import ProductEditScreen from './Screen/ProductEditScreen'
import OrderListScreen from './Screen/OrderListScreen'


function App() {
  return (
    <Router>
      <Header />
           <main className="py-3">
             <Container>
                    <Route path='/' component={HomeScreen} exact />
                    <Route path='/login' component={LoginScreen} />
                    <Route path='/register' component={RegisterScreen} />
                    <Route path='/profile' component={ProfileScreen} />
                    <Route path='/shipping' component={ShippingScreen} />
                    <Route path='/payment' component={PaymentScreen} />
                    <Route path='/placeorder' component={PlaceOrderScreen} />
                    <Route path='/order/:id' component={OrderScreen} />
                    <Route path='/product/:id' component={ProductScreen} />
                    <Route path='/cart/:id?' component={CartScreen} />

                    <Route path='/admin/user/:id/edit' component={UserEditScreen} />

                    <Route path='/admin/userlist' component={UserListScreen} />

                    <Route path='/admin/productlist' component={ProductListScreen} />
                    <Route path='/admin/product/:id/edit' component={ProductEditScreen} />

                    <Route path='/admin/orderlist' component={OrderListScreen} />


             </Container>
           </main>
      <Footer />
    </Router>
  );
}

export default App;
